import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.01,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.01,
    // If you don't want to use Session Replay, just remove the line below:
    // integrations: [new Replay()]
    denyUrls: ['https://imasdk.googleapis.com/', 'https://s0.2mdn.net/instream/video'],
});
const handleErrorAfterSentry = ({ error, event }) => {
    console.log(error);
    return {
        message: 'Whoops!',
        code: error?.code ?? 'UNKNOWN',
    };
};
export const handleError = handleErrorWithSentry(handleErrorAfterSentry);
